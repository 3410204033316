import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Preset, ROLES } from '@app/core/models/Preset';
import { Radio } from '@app/core/models/Radio';
import { Track } from '@app/core/models/Track';
import { MainSideNavService } from '@app/core/services/main-side-nav.service';
import {
  RoleHelperService,
  RoleOperatorActions,
  RoleOperators,
} from '@app/core/services/role-helper.service';
import { SetOnMobile, SetOnTablet } from '@app/core/states/app.actions';
import { AudioState } from '@app/core/states/audio.state';
import { DisplaySlideshow } from '@app/core/states/auth.actions';
import { AuthorizationState } from '@app/core/states/authorization.state';
import { SetEventProperties } from '@app/core/states/events-tracking.actions';
import {
  ApollonStatusNeedsRestart,
  DisplayUpgradeDiscoverModal,
} from '@app/core/states/live-tracking.actions';
import { LiveTrackingState } from '@app/core/states/live-tracking.state';
import { RadioState } from '@app/core/states/radio.state';
import { TracksState } from '@app/library/states/tracks.state';
import { aLogEvent, AMPLITUDE_EVENT } from '@app/shared/amplitude-events';
import { MobOnboardingComponent } from '@app/shared/components/mob-onboarding/mob-onboarding.component';
import {
  FoldContainerSidebarComponent,
  LOCAL_STORAGE_FOLD_KEY,
} from '@app/shared/components/shell/fold-container-sidebar/fold-container-sidebar.component';
import { SlideshowComponent } from '@app/shared/components/slideshow/slideshow.component';
import { SavedToLocalstorage } from '@app/shared/constants';
import { ApollonUpdateModalComponent } from '@app/shared/modals/apollon-update-modal/apollon-update-modal.component';
import { UpgradeDiscoverModalComponent } from '@app/shared/modals/upgrade-discover-modal/upgrade-discover-modal.component';
import { UpgradeOfferModalComponent } from '@app/shared/modals/upgrade-offer/components/upgrade-offer-modal/upgrade-offer-modal.component';
import { baseNav } from '@app/shared/modules/navigation/navigation.model';
import { ModelNav } from '@app/shared/modules/navigation/navigation.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import {
  ChecklistRequest,
  ChecklistSuccess,
  OnboardingChecklistState,
} from '@radioking/onboarding-checklist';
import {
  MobileSidenavService,
  UiStoreHelperService,
} from '@radioking/shared/common-services';
import { PanelsState } from '@radioking/shared/pannel-manager';
import { combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  first,
  mergeMap,
  pairwise,
  startWith,
  tap,
  throttleTime,
} from 'rxjs/operators';

import { BREVO_EVENT } from '@app/shared/modules/brevo-events';
import { BrevoService } from 'libs/shared/common-services/src/lib/services/brevo.service';
import { DashboardState } from '../../../../../../libs/dashboard/src/lib/states/dashboard.state';
import { ResetAllTracks } from '../../../../../../libs/scheduler-core/src/lib/states/scheduler-core.actions';
import { RadioStartRequest } from '../states/radio.actions';

@Component({
  selector: 'rk-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class.is-mobile')
  isOnMobile = false;

  navigation = baseNav;

  @HostBinding('class.folded-side')
  folded = false;

  @ViewChild('sidebar', { static: true })
  sidebar: FoldContainerSidebarComponent;

  menuClosed = false;

  @Select(LiveTrackingState.planId)
  radioPlan$: Observable<number>;

  @Select(RadioState.currentRadio)
  radio$: Observable<Radio>;

  @Select(LiveTrackingState.demoRemainingDays)
  demoRemainingDays$: Observable<number>;

  @Select(RadioState.changeOptionsUrl)
  increaseDiskSpaceUrl$: Observable<string>;

  @Select(DashboardState.diskSpacePercent)
  diskSpace$: Observable<number>;

  @Select(DashboardState.listeningTimePercent)
  listeningTime$: Observable<number>;

  @Select(AuthorizationState.roles)
  userRoles$: Observable<Preset[]>;

  @Select(RadioState.newRadio)
  newRadio$: Observable<boolean>;

  @Select(AudioState.currentTrackPlaying)
  listening$: Observable<Track>;

  @Select(OnboardingChecklistState.destroyed)
  destroyed$: Observable<boolean>;

  @Select(PanelsState.lessOpacity)
  lessOpacity$: Observable<boolean>;

  showChecklist$: Observable<boolean>;

  dialogRef: MatDialogRef<ApollonUpdateModalComponent>;
  slideshowDialogRef: MatDialogRef<SlideshowComponent>;
  subscription = new Subscription();

  constructor(
    private readonly router: Router,
    private readonly sideNavService: MainSideNavService,
    private readonly mainSideNavService: MainSideNavService,
    private readonly mobileSidenavService: MobileSidenavService,
    private readonly _rolesService: RoleHelperService,
    private readonly helper: UiStoreHelperService,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly actions$: Actions,
    private readonly brevoService: BrevoService,
  ) {}

  ngOnInit() {
    this.sideNavService.setSideNav(this.sidenav);
    this.setIsMobile(window.innerWidth);
    this.menuClosed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FOLD_KEY));

    this.subscription.add(
      fromEvent(window, 'resize')
        .pipe(debounceTime(100))
        .subscribe(w => {
          if (w && w.target) {
            this.setIsMobile((w.target as Window).innerWidth);
          }
        }),
    );

    let startUrl = this.router.url.split('/').slice(0, 3).join('/');

    let finalUrl = '/' + this.router.url.split('/').slice(3).join('/');

    this.subscription.add(
      this.radio$
        .pipe(
          filter(val => !!val),
          distinctUntilChanged(),
          mergeMap(radio => {
            if (!radio.name) {
              this.router.navigateByUrl(
                '/radio/' +
                  this.store.selectSnapshot(RadioState.currentRadioId) +
                  '/configure-radio',
              );

              return of(null);
            } else {
              return this.userRoles$;
            }
          }),
          filter(roles => roles && roles.length > 0),
          debounceTime(1),
        )
        .subscribe(() => {
          startUrl = this.router.url.split('/').slice(0, 3).join('/');

          finalUrl = '/' + this.router.url.split('/').slice(3).join('/');

          this.processRouteRights(startUrl, finalUrl);
        }),
    );

    this.subscription.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          tap(() => this.debounceClose()),
          mergeMap(() => this.userRoles$),
          filter(roles => roles && roles.length > 0),
          debounceTime(1),
        )
        .subscribe(() => {
          startUrl = this.router.url.split('/').slice(0, 3).join('/');

          finalUrl = '/' + this.router.url.split('/').slice(3).join('/');

          this.processRouteRights(startUrl, finalUrl);
        }),
    );

    this.subscription.add(
      this.actions$
        .pipe(
          ofActionDispatched(ApollonStatusNeedsRestart),
          filter(() => window.innerWidth > 767),
          tap(() => {
            this.apollonDialog();
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.actions$
        .pipe(
          ofActionDispatched(DisplayUpgradeDiscoverModal),
          tap(({ props }) => {
            if (!this.dialog.openDialogs.length) {
              if (props) {
                this.store.dispatch(new SetEventProperties(props));
              }
              this.discoverModal();
            }
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.actions$.pipe(ofActionDispatched(DisplaySlideshow)).subscribe(() => {
        if (!this.isOnMobile) {
          this.slideDialog();
        }
      }),
    );

    this.showChecklist$ = this.helper.getLoadingState(
      ChecklistSuccess,
      ChecklistRequest,
      false,
    );

    this.subscription.add(
      // Checklist is only available in demo
      combineLatest([
        this.radio$,
        this.radioPlan$.pipe(filter(p => !!p && p < 2)),
      ]).subscribe(this.getChecklist),
    );

    // Reset "all tracks" to force update when needed
    this.subscription.add(
      this.store
        .select(TracksState.allTracksForDropdown)
        .pipe(
          distinctUntilChanged(),
          throttleTime(1000),
          startWith([]),
          pairwise(),
          filter(([p, n]) => !!(p?.length && n?.length)),
        )
        .subscribe(_ => {
          this.store.dispatch(new ResetAllTracks());
        }),
    );

    this.subscription.add(
      combineLatest([
        this.store.select(LiveTrackingState.planDesc),
        this.actions$.pipe(ofActionDispatched(RadioStartRequest)),
      ])
        .pipe(
          filter(([planDesc, _]) => planDesc === 'Demo'),
          first(),
        )
        .subscribe(() => {
          this.brevoService.trackEvent(BREVO_EVENT.RADIO_STARTED);
        }),
    );

    window.addEventListener('resize', this.updateVH);
    this.updateVH();
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.radio$.pipe(delay(500)).subscribe(radio => {
        if (
          radio &&
          radio.name &&
          window.innerWidth < 768 &&
          !localStorage.getItem(SavedToLocalstorage.ONBOARDING_MOBILE)
        ) {
          this.mobileSidenavService.open(MobOnboardingComponent, { from: 'full' });
        }
      }),
    );
  }

  setIsMobile(w: number) {
    let tablet = false;
    let mobile = false;
    if (w < 1081) {
      tablet = true;
      this.menuClosed = true;
      this.setMobile();
    } else {
      this.menuClosed = false;
      this.setDesktop();
    }
    if (w < 768) {
      mobile = true;
    }
    if (this.isOnMobile && !tablet) {
      this.menuClosed = false;
    }
    if (!this.isOnMobile && tablet) {
      this.menuClosed = true;
    }
    this.isOnMobile = tablet;
    if (!tablet) {
      this.setDesktop();
    }
    this.setOnMobile(mobile);
    this.setOnTablet(tablet);
  }

  updateVH() {
    const r: HTMLStyleElement = document.querySelector(':root');
    const vh = window.innerHeight * 0.01;
    r.style.setProperty('--vh', `${vh}px`);
  }

  debounceClose() {
    setTimeout(() => {
      if (this.isOnMobile) {
        this.sidenav.close();
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.mobileSidenavService.close();
    this.subscription.unsubscribe();
  }

  slideDialog() {
    if (
      this.slideshowDialogRef ||
      this.store.selectSnapshot(LiveTrackingState.planDesc) !== 'Demo'
    ) {
      return;
    }
    this.slideshowDialogRef = this.dialog.open(SlideshowComponent, {
      backdropClass: 'darken',
    });
    this.slideshowDialogRef.afterClosed().subscribe((quit = true) => {
      if (quit) {
        aLogEvent(AMPLITUDE_EVENT.ONBOARDING_SLIDESHOW.CLOSE);
      }
      this.slideshowDialogRef = null;
    });
  }

  apollonDialog() {
    if (this.dialogRef) {
      return;
    }
    this.dialogRef = this.dialog.open(ApollonUpdateModalComponent);
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }

  async processRouteRights(startUrl: string, finalUrl: string): Promise<boolean | void> {
    if (finalUrl === '/' || finalUrl === '/configure-radio') {
      return;
    }

    if (finalUrl.indexOf('/settings') > -1) {
      this._rolesService
        .hasRole({
          type: RoleOperatorActions.SPECIFIC,
          roleId: ROLES.SETTINGS,
        })
        .pipe(first())
        .subscribe(async (hasRole): Promise<boolean | void> => {
          if (!hasRole) {
            return this.router.navigateByUrl(startUrl + '/404');
          }
        });
    } else {
      const nav = this.findByUrl(finalUrl);

      if (!nav) {
        return this.router.navigateByUrl(startUrl + '/404');
      }

      if (nav.roles) {
        const roles: RoleOperators =
          typeof nav.roles !== 'string'
            ? nav.roles
            : {
                type: RoleOperatorActions.SPECIFIC,
                roleId: nav.roles,
              };

        this._rolesService
          .hasRole(roles)
          .pipe(first())
          .subscribe(async (hasRole): Promise<boolean | void> => {
            if (!hasRole) {
              return this.router.navigateByUrl(startUrl + '/404');
            }
          });
      }
    }
  }

  findByUrl(finalURL: string): ModelNav {
    for (const baseNavItem of baseNav) {
      if (finalURL.indexOf(baseNavItem.url) > -1) {
        return baseNavItem;
      }
      if (baseNavItem.children) {
        for (const child of baseNavItem.children) {
          if (!child.roles) {
            child.roles = baseNavItem.roles;
          }
          if (finalURL.indexOf(child.url) > -1) {
            return child;
          }
        }
      }
    }

    return null;
  }

  @ViewChild('sidenav', { static: true })
  set sidenav(nav: MatSidenav) {
    this.sideNavService.setSideNav(nav);
  }

  get sidenav(): MatSidenav {
    return this.sideNavService.getSideNav();
  }

  @ViewChild(FoldContainerSidebarComponent, { static: true })
  set foldContainer(nav: FoldContainerSidebarComponent) {
    this.sideNavService.setFoldContener(nav);
  }

  changeFold(value: boolean) {
    setTimeout(() => {
      this.folded = value;
    }, 1);
  }

  private setMobile() {
    this.sidenav.close();
    this.isOnMobile = true;
    this.sideNavService.getFoldContener().unfold();
  }

  private setDesktop() {
    this.sidenav.open();
    this.isOnMobile = false;
  }

  onTouchFold() {
    this.sidebar.onTouchFold();
  }

  toggleSideNav() {
    this.menuClosed = this.isOnMobile ? true : !this.menuClosed;
    if (this.isOnMobile) {
      this.mainSideNavService.toggleSideNav();
    } else {
      this.mainSideNavService.toggleFold();
      // Hack when ce fold menu and still hover it issue
      if (
        this.menuClosed &&
        this.sidebar.getElRef().nativeElement.classList.contains('unfolded')
      ) {
        this.sidebar.getElRef().nativeElement.classList.remove('unfolded');
      }
    }
  }

  updateOffer(): void {
    aLogEvent(AMPLITUDE_EVENT.UPGRADE_MODAL.OPEN);
    aLogEvent(AMPLITUDE_EVENT.ONBOARDING_DASHBOARD.CTA_CONVERSION_SIDEBAR);
    this.dialog.open(UpgradeOfferModalComponent, { data: 'menu_demo_container' });
  }

  discoverModal(): void {
    this.dialog.open(UpgradeDiscoverModalComponent);
  }

  showUpgradeBlock(): boolean {
    return (
      this.store.selectSnapshot(LiveTrackingState.planId) === 1 &&
      this.store.selectSnapshot(LiveTrackingState.demoRemainingDays) < 12
    );
  }

  @Dispatch()
  setOnMobile(mobile: boolean) {
    return new SetOnMobile(mobile);
  }

  @Dispatch()
  setOnTablet(mobile: boolean) {
    return new SetOnTablet(mobile);
  }

  @Dispatch()
  getChecklist = () => new ChecklistRequest();
}
